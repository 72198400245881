const Header = ({ phone, email, navLinks }) => (
  <header className="absolute top-0 left-0 w-100 bg-transparent">
    <a href="#main-content" className="sr-only">
      Skip to main content
    </a>

    <div
      className="container flex justify-between pv3 mukta fw7 ttu white"
      style={{ letterSpacing: `0.025rem` }}
    >
      <Logo className="flex "/>

      <div className="flex items-center ">
        <Phone phone={phone} />
        <BurgerAndOverlay navLinks={navLinks} phone={phone} email={email} />
      </div>
    </div>
  </header>
)

/*
 *
 * Logo
 *
 */

const Logo = () => (
  <Link
    to="/"
    className="relative z-999 w-60"
    style={{ maxWidth: `20rem`, fontSize: 0 }}
  >
    <LogoSVG />
    <span className="sr-only">Kings Valley Landscaping</span>
  </Link>
)

/*
 *
 * Phone
 *
 */

const Phone = ({ phone }) => {
  let phoneLinks = Array.prototype.concat(phone.tollFree, phone.local)

  return (
    <nav aria-label="Telephone links" className="dn lg:flex justify-end">
      <PhoneSVG className="icon relative z-1 mr2 rotate-90" />

      <ul className="flex">
        {phoneLinks.map((contact, i) => (
          <li key={i} className={`order-${i}`}>
            <Anchor
              href={contact.href}
              className="relative z-999 animate hover:green"
            >
              {contact.text}
            </Anchor>
          </li>
        ))}
        <li className="relative z-1 order-0 ph2">|</li>
      </ul>
    </nav>
  )
}

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { Link } from 'gatsby'

import Anchor from '../components/Anchor'
import BurgerAndOverlay from '../components/BurgerAndOverlay'

import { ReactComponent as LogoSVG } from '../svg/logo.svg'
import { ReactComponent as PhoneSVG } from '../svg/phone.svg'

export default Header
