const Footer = ({ services, phone, email, address, socialLinks }) => {
  // Remove Asphalt from the list of services shown on the Home page
  const footerServices = services.filter(
    service => service.node.heading !== 'Asphalt Maintenance'
  )

  return (
    <footer className="mukta dark-gray">
      <Banner email={email} />
      <BannerKVP />
      <Content
        services={footerServices}
        phone={phone}
        email={email}
        address={address}
      />
      <Branding socialLinks={socialLinks} />
    </footer>
  )
}

/*
 *
 * Banner
 *
 */

const Banner = ({ email }) => (
  <section className="bg-green pb4">
    <div className="container flex flex-wrap justify-start  items-center pv3 lg:pv2">
      <h2 className="heading white mt4 pr3 f3 min-w-70 lh-title">
        Schedule a free consultation today
      </h2>

      <Anchor href={email.href} className="link heading mt4 f4 white">
        {email.pitch}
      </Anchor>
    </div>
  </section>
)



const BannerKVP = ({ }) => (
  <section className="bg-yellow pb4">
    <div className="container flex flex-wrap justify-start  items-center pv3 lg:pv2">
      <h2 className="heading white mt4 pr3 f3 min-w-70 lh-title">
        Need Custom Asphalt Paving?
      </h2>

      <Anchor href='https://kingsvalleypaving.com/' className="link heading mt4 f4 white">
        Kings Valley Paving
      </Anchor>
    </div>
  </section>
)

/*
 *
 * Links
 *
 */

const Content = ({ services, phone, email, address }) => (
  <section className="bg-dark-gray pv5 white">
    <div className="footer-grid container pv3 sm:pv4 lg:pv5">
      <Services services={services} />
      <Contact phone={phone} email={email} />
      <Address address={address} />
    </div>
  </section>
)

/*
 *
 * Services
 *
 */

const Services = ({ services }) => (
  <div aria-label="Main navigation">
    <h2 className="heading mb3 f4">Services</h2>

      <ul className="lh-tall moon-gray db mb4 pb3">
        {services.map((service, i) => (
          <li key={i} >
            {service.node.heading}
          </li>
        ))}
      </ul>

  </div>
)

/*
 *
 * Contact
 *
 */

const Contact = ({ phone, email }) => {
  let contactLinks = Array.prototype.concat(phone.local, phone.tollFree, email)

  return (
    <nav aria-label="Contact links">
      <h2 className="heading mb3 f4">Contact Us</h2>

      <dl className="mb4 pb3 lh-tall moon-gray">
        {contactLinks.map((contact, i) => (
          <Fragment key={i}>
            <dt className="dn xl:dib fl mr3" style={{ width: `4rem` }}>
              {contact.label}:
            </dt>
            <dd>
              <Anchor href={contact.href} className="dib">
                {contact.text}
              </Anchor>
            </dd>
          </Fragment>
        ))}
      </dl>
    </nav>
  )
}

/*
 *
 * Address
 *
 */

const Address = ({ address }) => (
  <nav aria-label="Link to map of location">
    <h2 className="heading f4 mb3">Address</h2>

    <Anchor href={address.href} className="lh-tall moon-gray">
      <span className="db">{address.title}</span>
      <span className="db" dangerouslySetInnerHTML={{ __html: address.street }} />
      <span className="db">{address.city}</span>
    </Anchor>
  </nav>
)

/*
 *
 * Branding
 *
 */

const Branding = ({ socialLinks }) => (
  <section className=" pv5 white footerImage">
    <div className="container tc">
      <LogoSVG className="mb4 mw5" />
      <Socials socialLinks={socialLinks} />
      <Copyright />
    </div>
  </section>
)

/*
 *
 * Socials
 *
 */

const Socials = ({ socialLinks }) => (
  <nav aria-label="Social media">
    <ul className="flex justify-center mb4 pt2 f3 white">
      {socialLinks.map((platform, i) => (
        <PlatformLink key={i} href={platform.href} text={platform.text} />
      ))}
    </ul>
  </nav>
)

const PlatformLink = ({ href, text }) => {
  let icon
  if (text === 'Facebook') icon = <FacebookSVG className="icon" />
  if (text === 'Twitter') icon = <TwitterSVG className="icon" />
  if (text === 'Instagram') icon = <InstagramSVG className="icon" />
  if (text === 'YouTube') icon = <YouTubeSVG className="icon" />
  if (text === 'LinkedIn') icon = <LinkedInSVG className="icon" />

  return (
    <li className="mh3">
      <Anchor
        href={href}
        srText={`Visit our ${text} page`}
        className="db lh-solid animate hover:green"
      >
        {icon}
      </Anchor>
    </li>
  )
}

/*
 *
 * Copyright
 *
 */

const Copyright = () => (
  <p className="lh-tall f6 white">
    &copy; {new Date().getFullYear()} Kings Valley Landscape Contractors.
    All&nbsp;rights&nbsp;reserved. Brewed&nbsp;by&nbsp;
    <Anchor href="http://coffeeshopcreative.ca" className="animate hover:green">
      Coffeeshop&nbsp;Creative
    </Anchor>
    .
  </p>
)

/*
 *
 * Imports & Exports
 *
 */

import React, { Fragment } from 'react'
// import { Link } from 'gatsby'

import Anchor from '../components/Anchor'

import { ReactComponent as LogoSVG } from '../svg/logo.svg'
import { ReactComponent as FacebookSVG } from '../svg/facebook-f.svg'
import { ReactComponent as TwitterSVG } from '../svg/twitter.svg'
import { ReactComponent as InstagramSVG } from '../svg/instagram.svg'
import { ReactComponent as YouTubeSVG } from '../svg/youtube.svg'
import { ReactComponent as LinkedInSVG } from '../svg/linkedin-in.svg'

export default Footer
