class Hero extends Component {
  state = { includeVideo: false, videoIsLoaded: false }


  render() {
    const { title, tagline, link, image } = this.props

    return (
      <section className="relative min-vh-100 max-h7">
        <div className="hero-padding relative z-1 container flex items-end min-vh-100 max-h7">
          <div className="white">
            <h1 className="page-heading mb3" style={{ maxWidth: `16ch` }}>
              {title.white}
              <br />
              <span className="yellow">{title.yellow}</span>
            </h1>

            <p
              className="mb4 lh-title f4 fw7 ttu"
              style={{
                maxWidth: `36ch`
              }}
            >
              {tagline}
            </p>

            <ScrollTo
              href={link.href}
              className="link-yellow heading f4 white"
            >
              {link.text}
            </ScrollTo>
          </div>
        </div>

        <Img
          fluid={image.file.childImageSharp.fluid}
          alt={image.alt}
          objPosition={image.objPosition}
          className="fill"
          style={{ position: 'absolute', zIndex: -1 }}
        />


        <div className="absolute greenBorder bottom-0" />
      </section>
    )
  } 
}

/*
 * 
 * Imports & Exports
 * 
 */

import React, { Component } from 'react'

import Img from '../components/Img'
import ScrollTo from '../components/ScrollTo'


export default Hero
