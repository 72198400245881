const IndexPage = ({ data }) => {
  const hero = data.homeYaml.hero

  const contact = data.contactYaml

  const story = data.homeYaml.story

  const services = data.homeYaml.services
  const serviceList = data.allServiceListYaml.edges

  const testimonials = data.homeYaml.testimonials
  const testimonialsList = data.allTestimonialsYaml.edges

  const equipment = data.homeYaml.equipment
  // const equipmentList = data.allEquipmentYaml.edges


  const charities = data.homeYaml.charities

  // Remove Asphalt from the list of services shown on the Contact form
  const contactServiceList = serviceList.filter(
    service => service.node.heading !== 'Asphalt Maintenance'
  )

  // Remove Asphalt and Concrete from the list of services shown on the Home page
  const homeServiceList = serviceList.filter(
    service =>
      service.node.heading !== 'Asphalt Maintenance' &&
      service.node.heading !== 'Concrete'
  )

  // Extract the service headings for the HomeStory section
  const homeStoryServices = contactServiceList.map(service => service.node.heading)

  return (
    <Base>
      <main id="main-content" tabIndex="-1" className="mukta dark-gray">
        <Hero
          title={hero.title}
          tagline={hero.tagline}
          link={hero.link}
          image={hero.image}
        />

        <Contact
          stamp={contact.stamp}
          heading={contact.heading}
          invitation={contact.invitation}
          services={contactServiceList}
          successMessage={contact.successMessage}
        />

        <Story
          heading={story.heading}
          paragraphs={story.paragraphs}
          services={homeStoryServices}
          link={hero.link}
        />

        <Services
          heading={services.heading}
          paragraph={services.paragraph}
          services={homeServiceList}
        />

        <Testimonials
          heading={testimonials.heading}
          paragraph={testimonials.paragraph}
          testimonials={testimonialsList}
        />

        <Equipment
          heading={equipment.heading}
          paragraph={equipment.paragraph}
          equipment={equipment.list}
        />

        <Community
          heading={charities.heading}
          paragraph={charities.paragraph}
          charities={charities.list}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query {
    homeYaml {
      hero {
        title {
          white
          yellow
        }
        tagline
        link {
          href
          text
        }
        image {
          file {
            childImageSharp {
              fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
        }
      }
      story {
        heading {
          black
          green
        }
        paragraphs
        link {
          href
          text
        }
      }
      services {
        heading {
          black
          green
        }
        paragraph
      }
      testimonials {
        heading
        paragraph
      }
      equipment {
        heading {
          black
        }
        paragraph
        list
      }
      charities {
        heading {
          black
          green
        }
        paragraph
        list
      }
    }
    allServiceListYaml {
      edges {
        node {
          heading
          paragraph
          image {
            file {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            objPosition
          }
        }
      }
    }
    allTestimonialsYaml {
      edges {
        node {
          heading
          quotation
          source
        }
      }
    }
    contactYaml {
      stamp {
        largeText
        smallText
      }
      heading
      invitation
      successMessage
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Base from '../components/Base'
import Hero from '../components/Hero'
import Story from '../sections/HomeStory'
import Services from '../sections/HomeServices'
import Testimonials from '../sections/HomeTestimonials'
import Equipment from '../sections/HomeEquipment'
import Community from '../sections/HomeCommunity'
import Contact from '../sections/Contact'

export default IndexPage
