class BurgerAndOverlay extends Component {
  state = { menuOpen: false }

  // Bind modal to appElement (http://reactcommunity.org/react-modal/accessibility/)
  componentDidMount = () => Modal.setAppElement(`#___gatsby`)

  // Handle CSS hover effects with GSAP so I can reset the spans to the right transformY
  // If menu is closed, expand burger layers on hover (only if closed to avoid conflicts with rotate animation)
  handleBurgerMouseEnter = () => {
    if (!this.state.menuOpen) {
      loadjs.ready(`gsap`, () => {
        TweenLite.to(this.burgerTop, 0.05, { y: `-10px` })
        TweenLite.to(this.burgerBottom, 0.05, { y: `10px` })
      })
    }
  }

  // If menu is closed, contract burger layers after hover
  handleBurgerMouseLeave = () => {
    if (!this.state.menuOpen) {
      loadjs.ready(`gsap`, () => {
        TweenLite.to(this.burgerTop, 0.05, { y: `-8px` })
        TweenLite.to(this.burgerBottom, 0.05, { y: `8px` })
      })
    }
  }

  handleBurgerClick = () => {
    const { menuOpen } = this.state

    loadjs.ready(`gsap`, () => {
      // Define burger animation timeline
      const menuToggle = new TimelineLite({ paused: true })
      menuToggle
        .fromTo(this.burgerTop, 0.1, { y: `-10px` }, { y: 0 }, `collapse`)
        .fromTo(this.burgerBottom, 0.1, { y: `10px` }, { y: 0 }, `collapse`)
        .fromTo(this.burgerMiddle, 0.3, { opacity: 1 }, { opacity: 0 })
        .fromTo(this.burgerTop, 0.1, { rotationZ: 0 }, { rotationZ: -45 }, `rotate`)
        .fromTo(
          this.burgerBottom,
          0.1,
          { rotationZ: 0 },
          { rotationZ: 45 },
          `rotate`
        )

      if (menuOpen) {
        menuToggle.reverse() // animate burger
        this.closeMenu()
      } else {
        menuToggle.restart() // animate burger
        this.openMenu()
      }
    })
  }

  openMenu = () => {
    this.setState({ menuOpen: true })
    noScroll.on()
  }

  closeMenu = () => {
    noScroll.off()
    this.setState({ menuOpen: false })
  }

  render() {
    const { navLinks, phone, email } = this.props
    const { menuOpen } = this.state

    return (
      <>
        <button
          ref={el => (this.burger = el)}
          onMouseEnter={this.handleBurgerMouseEnter}
          onMouseLeave={this.handleBurgerMouseLeave}
          onClick={this.handleBurgerClick}
          className="burger"
        >
          <span className="sr-only">Click to open menu main navigation</span>

          <span className="db" style={{ width: `2rem` }}>
            <span
              ref={el => (this.burgerTop = el)}
              className="burger-layer top animate"
            />
            <span
              ref={el => (this.burgerMiddle = el)}
              className="burger-layer animate"
            />
            <span
              ref={el => (this.burgerBottom = el)}
              className="burger-layer bottom animate"
            />
          </span>

          <p
            className="dn sm:db ml5 mukta fw7 ttu tr"
            style={{ width: `4.25rem`, zIndex: `10001`, letterSpacing: `0.025rem` }}
          >
            {menuOpen ? 'Close' : 'Menu'}
          </p>
        </button>

        <Modal
          isOpen={menuOpen}
          onRequestClose={this.handleBurgerClick}
          closeTimeoutMS={500} // match exit animation timing
          overlayClassName="menu-modal-overlay fixed fill"
          className="menu-modal-content absolute right-0 top-0 bottom-0 overflow-auto scrolling-touch bg-dark-green"
        >
          <MenuContent
            navLinks={navLinks}
            phone={phone}
            email={email}
            closeMenu={this.handleBurgerClick}
          />
        </Modal>
      </>
    )
  }
}

/*
 *
 * Menu Content
 * 
 */

class MenuContent extends Component {
  state = { wideEnoughForEmailAddress: false }

  componentDidMount = () => {
    if (window.matchMedia(`(min-width: 48em)`).matches) {
      this.setState({ wideEnoughForEmailAddress: true })
    }
  }

  render() {
    const { navLinks, phone, email, closeMenu } = this.props
    const { wideEnoughForEmailAddress } = this.state

    let contactLinks = Array.prototype.concat(phone.tollFree, email)

    return (
      <aside
        onClick={closeMenu}
        className="pt5 pb4 ph3 md:ph4 lg:pr5 w-100 h-100 white"
      >
        <nav aria-label="Main navigation" onClick={closeMenu}>
          <ul className="pv4">
            {navLinks.map((link, i) => {
              return (
                <li key={i}>
                  <ScrollTo
                    href={link.href}
                    className="link-yellow heading pv2 f4"
                  >
                    {link.text}
                  </ScrollTo>
                </li>
              )
            })}
          </ul>
        </nav>

        <nav aria-label="Contact links" onClick={closeMenu}>
          <ul className="pt3 pb5 lh-tall">
            {contactLinks.map((link, i) => {
              return (
                <li key={i}>
                  <Anchor
                    href={link.href}
                    className={`link-yellow heading pv1 f6 ${
                      wideEnoughForEmailAddress ? `ttl` : ``
                    }`}
                  >
                    {wideEnoughForEmailAddress
                      ? link.text
                      : link.label === 'Email'
                        ? link.label
                        : link.text}
                  </Anchor>
                </li>
                
              )
            })}
            <li>
              <Anchor className="link-yellow heading pv1 f6" href="https://kingsvalleypaving.com/">
                Kings Valley Paving
              </Anchor>
            </li>
          </ul>
        </nav>
      </aside>
    )
  }
}

/*
 *
 * Imports & Exports
 * 
 */

import React, { Component } from 'react'
import { Link } from 'gatsby'
import loadjs from 'loadjs'
import Modal from 'react-modal'
import noScroll from 'no-scroll'
import ScrollTo from '../components/ScrollTo'

import Anchor from '../components/Anchor'

export default BurgerAndOverlay
