const HomeCommunity = ({ heading, paragraph, charities }) => (
  <section className="bg-white pv5" id="community">
    <div className="sm:pv3 md:pb4 lg:pt4 lg:pb5">
      <div className="container">
        <h2 className="section-heading mb4 sm:pb3">
          {heading.black}
          <span className="db green">{heading.green}</span>
        </h2>

        <p className="sm:pb3 measure lh-copy">{paragraph}</p>

        <h2 className="section-subheading ttc">Proudly Sponsoring</h2>
        <ul className="clients-columns mt4 lh-title sm:f4">
                {charities.map((charities, i) => (
                <li key={i}>{charities}</li>
                ))}
            </ul>
      </div>
    </div>
  </section>
)



/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

// import Accordion from '../components/Accordion'
// import Limit from '../components/Limit'

export default HomeCommunity
