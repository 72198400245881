const HomeServices = ({ heading, paragraph, services }) => (
  <section className="container pv5 sm:mv3 md:mv4 lg:mv5" id="services">
    <h2 className="section-heading mb4 sm:pb3">
      {heading.black}
      <span className="db green">{heading.green}</span>
    </h2>

    <p className="measure-wide lh-copy">{paragraph}</p>

    <ul>
      {services.map(service => (
        <Service key={service.node.heading} service={service.node} />
      ))}
    </ul>

  </section>
)

/*
 *
 * Service
 *
 */

const Service = ({ service }) => (
  <li className="services-grid mt5">
    <Img
      fluid={service.image.file.childImageSharp.fluid}
      alt={service.image.alt}
      objPosition={service.image.objPosition}
      className="thumbnail b--white mb4"
    />

    <div>
      <h3 className="section-subheading mb3 sm:pb2">{service.heading}</h3>
      <p
        className="measure-wide lh-copy"
        dangerouslySetInnerHTML={{ __html: service.paragraph }}
      />
    </div>
  </li>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { Link } from 'gatsby'

import Img from '../components/Img'

export default HomeServices
