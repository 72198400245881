const HomeTestimonials = ({ heading, paragraph, testimonials }) => (
  <section className="bg-near-white pt5" id="testimonials">
    <div className="container sm:pt3 md:pt4 lg:pt5">
      <h2 className="section-heading mb4 sm:pb3">{heading}</h2>

      <p className="sm:pb3 measure lh-copy">{paragraph}</p>
    </div>

    <ul>
      {testimonials.map((testimonial, i) => (
        <li key={i}>
          <Accordion
            renderHeader={expanded => (
              <Header testimonial={testimonial.node} expanded={expanded} />
            )}
            renderDetails={() => <Details testimonial={testimonial.node} />}
          />
        </li>
      ))}
    </ul>
  </section>
)

/* 
 *
 * Header
 * 
 */

const Header = ({ expanded, testimonial }) => (
  <div className="container group flex justify-between items-start mt2">
    <h2 className="section-subheading ttc green">{testimonial.heading}</h2>

    <Icon expanded={expanded} />
  </div>
)

const Icon = ({ expanded }) => (
  <span
    aria-hidden="true"
    className={`heading dn md:db green animate${!expanded &&
      ` o-0 group-hover:o-100`}`}
    style={{ fontSize: `2.5rem`, lineHeight: 0.7 }}
  >
    {expanded ? `-` : `+`}
  </span>
)

/*
 *
 * Details
 *
 */

const Details = ({ testimonial }) => (
  <div className="container mt3 pt2">
    <Quote quote={testimonial.quotation} source={testimonial.source} />
  </div>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Accordion from '../components/Accordion'
import Quote from '../components/Quote'

export default HomeTestimonials
