const HomeEquipment = ({ heading, paragraph, equipment }) => (
  
    <section className="bg-near-white pv5">
        <div className="sm:pv3 md:pb4 lg:pt4 lg:pb5 container">
            <h2 className="section-heading mb4 sm:pb3">{heading.black}</h2>

            <p className="sm:pb3 measure lh-copy">{paragraph}</p>

           <ul className="heading mt4 lh-title sm:f4 green">
                {equipment.map((equipment, i) => (
                <li key={i}>{equipment}</li>
                ))}
            </ul>
        </div>
    </section>
    
)
    
/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

export default HomeEquipment